import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import APIController from '../helpers/APIController';
import PasswordValidator from '../helpers/PasswordValidator';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

//class Login extends React.PureComponent<LoginProps> {
class AccountChangePassword extends BaseComponent<LoginProps, any> {
    state = {
        currentPassword: '',
        newPassword: '',
        newPassword2: '',

        currentPasswordEmpty: false,
        newPasswordEmpty: false,
        newPassword2Empty: false,
        incorrectNewPassword: false,
        differentPasswords: false,
        serverError: '',

        passwordChanged: false
    }


    public renderChild() {
        return (
            <div className="form">
                <h2>Zmiana hasła</h2>
                <label>Obecne hasło</label><input className={`${(this.state.currentPasswordEmpty) ? "validation-warning" : ""}`} name="currentPassword" type="password" value={this.state.currentPassword} onChange={(e) => super.handleInputChange(e)} />
                {this.state.currentPasswordEmpty && <p className="warning-message">Nie podano obecnego hasła</p>}
                <label>Nowe hasło</label><input className={`${(this.state.newPasswordEmpty || this.state.incorrectNewPassword || this.state.differentPasswords) ? "validation-warning" : ""}`} name="newPassword" type="password" value={this.state.newPassword} onChange={(e) => super.handleInputChange(e)} />
                {this.state.newPasswordEmpty && <p className="warning-message">Nie podano nowego hasła</p>}
                {this.state.incorrectNewPassword && <p className="warning-message">Nieprawidłowe nowe hasło. Hasło musi posiadać conajmniej 8 znaków</p>}
                <label>Powtórz nowe hasło</label><input className={`${(this.state.newPassword2Empty || this.state.incorrectNewPassword || this.state.differentPasswords) ? "validation-warning" : ""}`} name="newPassword2" type="password" value={this.state.newPassword2} onChange={(e) => super.handleInputChange(e)} />
                {this.state.newPassword2Empty && <p className="warning-message">Nie powtórzono nowego hasła</p>}
                {this.state.differentPasswords && <p className="warning-message">Podane hasła nie są jednakowe</p>}
                <button onClick={() => this.handleOnClickChangePassword()}>Zmień hasło</button>
                {this.state.passwordChanged && <p>Hasło zostało zmienione</p>}
                {this.state.serverError && <p className="warning-message">{this.state.serverError}</p>}
            </div>
        );
    }

    private async handleOnClickChangePassword(): Promise<void> {
        await super.load(() => this.changePassword())
    }

    private async changePassword() : Promise<void> {
        if (this.check()) {

            const apiController: APIController = new APIController(this.props.token);
            try {
                
                await apiController.changePassword(this.state.currentPassword, this.state.newPassword);
                this.setState({
                    currentPasswordEmpty: false,
                    newPasswordEmpty: false,
                    newPassword2Empty: false,
                    inncorectNewPassword: false,
                    serverError: '',
                    passwordChanged: true
                })
            }
            catch (error : any) {
                super.setState({
                    serverError: error
                })
            }   
        }
    }

    private check(): boolean {
        //TODO: Próba zmiany hasła na to samo
        const currentPasswordEmpty: boolean = !Boolean(this.state.currentPassword);
        const newPasswordEmpty: boolean = !Boolean(this.state.newPassword);
        const newPassword2Empty: boolean = !Boolean(this.state.newPassword2);
        let incorrectNewPassword: boolean = false;
        let differentPasswords: boolean = false;

        if (!newPasswordEmpty)
            incorrectNewPassword = !PasswordValidator.isPasswordValid(this.state.newPassword);

        if (!newPasswordEmpty && !newPassword2Empty)
            differentPasswords = this.state.newPassword !== this.state.newPassword2;

        this.setState({
            currentPasswordEmpty: currentPasswordEmpty,
            newPasswordEmpty: newPasswordEmpty,
            newPasswordEmpty2: newPassword2Empty,
            incorrectNewPassword: incorrectNewPassword,
            differentPasswords: differentPasswords,
            passwordChanged: false
        });

        if (currentPasswordEmpty || newPasswordEmpty || newPassword2Empty || incorrectNewPassword || differentPasswords)         
            return false;
        else
            return true;
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(AccountChangePassword as any);
