export default class PasswordValidator {
    public static isPasswordValid(password: string): boolean {
        const passwordToShort: boolean = password.length < 8;

        if (passwordToShort)
            return false;
        else
            return true;
    }
}
