import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import APIController from '../helpers/APIController';
import EmailValidator from '../helpers/EmailValidator';
import PasswordValidator from '../helpers/PasswordValidator';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';
import LoadScreen from './LoadScreen';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

type S =
    {

    }

class Register extends BaseComponent<LoginProps, any>{
    state = {
        email: '',
        password: '',
        password2: '',
        rodoAgree: false,
        adsAgree: false,

        emailEmpty: false,
        passwordEmpty: false,
        password2Empty: false,
        noRodoAgree: false,
        incorrectEmail: false,
        incorrectPassword: false,
        differentPasswords: false,
        serverError: ''
    }

    public render() {
        return (
            <React.Fragment>
                <React.Fragment>
                    {
                        this.props.logged ?
                            <Redirect to='/' />
                            :
                            <div className="register-container">
                                <h1>Rejestracja</h1>
                                <label>Email</label><input className={`${this.state.emailEmpty || this.state.incorrectEmail ? "validation-warning" : ""}`} name="email" type="email" value={this.state.email} onChange={(e) => super.handleInputChange(e)} />
                                {this.state.emailEmpty && <p className="warning-message">Nie podano adresu email</p>}
                                {this.state.incorrectEmail && <p className="warning-message">Nieprawidłowy adres email</p>}
                                <label>Hasło</label><input className={`${this.state.passwordEmpty || this.state.incorrectPassword || this.state.differentPasswords ? "validation-warning" : ""}`} name="password" type="password" value={this.state.password} onChange={(e) => super.handleInputChange(e)} />
                                {this.state.passwordEmpty && <p className="warning-message">Nie podano hasła</p>}
                                {this.state.incorrectPassword && <p className="warning-message">Nieprawidłowe hasło. Hasło musi zawierać przynajmniej 8 znaków</p>}
                                <label>Powtórz hasło</label><input className={`${this.state.password2Empty || this.state.incorrectPassword || this.state.differentPasswords ? "validation-warning" : ""}`} name="password2" type="password" value={this.state.password2} onChange={(e) => super.handleInputChange(e)} />
                                {this.state.password2Empty && <p className="warning-message">Nie powtórzono hasła</p>}
                                {this.state.differentPasswords && <p className="warning-message">Podane hasła nie są jednakowe</p>}
                                <label className="label-checkbox"><input className={`${this.state.noRodoAgree ? "validation-warning" : ""}`} name="rodoAgree" type="checkbox" checked={this.state.rodoAgree} onChange={(e) => super.handleInputChange(e)} /> * Wyrażam zgodę na przetwarzanie moich danych osobowych przez administratora danych w serwisie Dicti.eu celem realizacji oferowanych usług.</label>
                                {this.state.noRodoAgree && <p className="warning-message">Wymagane jest wyrażenie zgody na przetwarzanie danych osobowych</p>}
                                <label className="label-checkbox"><input name="adsAgree" type="checkbox" checked={this.state.adsAgree} onChange={(e) => super.handleInputChange(e)} /> Wyrażam zgodę na przetwarzanie moich danych osobowych przez administratora w serwisie Dicti.eu w celach marketingowych. </label>
                                {this.state.serverError && <p className="warning-message">{this.state.serverError}</p>}
                                <button onClick={() => this.register()}>Zarejestruj</button>
                            </div>
                    }
                </React.Fragment>
            </React.Fragment>
        )
    }


    private async register() {
        if (this.check()) {

            const apiController: APIController = new APIController(this.props.token);
            try {
                this.props.startLoad();
                try {
                    await apiController.register(this.state.email, this.state.password, this.state.adsAgree);
                    this.props.login(this.state.email, this.state.password);
                }
                catch(error) {
                    this.setState({
                        serverError: error
                    })
                }

                this.props.endLoad();
            }
            catch (error : any) {
                super.setState({
                    serverError: error.message
                })
            }
        }
    }

    private check(): boolean {
        const emailEmpty: boolean = !Boolean(this.state.email);
        const passwordEmpty: boolean = !Boolean(this.state.password);
        const password2Empty: boolean = !Boolean(this.state.password2);
        const noRodoAgree: boolean = !this.state.rodoAgree;
        let incorrectEmail: boolean = false;
        let incorrectPassword: boolean = false;
        let differentPasswords: boolean = false;

        if (!emailEmpty && !EmailValidator.isEmailValid(this.state.email))
            incorrectEmail = true;

        if (!passwordEmpty && !PasswordValidator.isPasswordValid(this.state.password)) {
            incorrectPassword = true;
        }

        if (!passwordEmpty && !password2Empty)
            if (this.state.password !== this.state.password2)
                differentPasswords = true;

        this.setState({
            emailEmpty: emailEmpty,
            passwordEmpty: passwordEmpty,
            password2Empty: password2Empty,
            noRodoAgree: noRodoAgree,
            incorrectEmail: incorrectEmail,
            incorrectPassword: incorrectPassword,
            differentPasswords: differentPasswords
        })

        if (emailEmpty || passwordEmpty || password2Empty || noRodoAgree || incorrectEmail || incorrectPassword || differentPasswords)
            return false;
        else
            return true;
    }
}


export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Register as any);
