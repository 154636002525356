import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import SpeechController from '../helpers/SpeechController';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';

class NumberToUpperIndexConverter extends BaseComponent<any, any>{
    render() {
        return(
            this.convert()
            )
    }

    private convert(): React.ReactNode {

        //const text = this.props.text.replace(/[0-9]/g, '');
        //const sense = this.props.text.match(/(\d+)/);

        return (
            <React.Fragment>
                {this.props.text}
                {/*{text}*/}
                {/*{sense && <sup>{sense[0]}</sup>}*/}
            </React.Fragment>
        )
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(NumberToUpperIndexConverter as any);
