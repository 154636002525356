import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Container } from 'reactstrap';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import Footer from './Footer';
import LoadScreen from './LoadScreen';
import NavMenu from './NavMenu';
import PopupCookies from './PopupCookies';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

class Layout extends React.PureComponent<LoginProps, { children?: React.ReactNode }> {
    public render() {
        return (
            <React.Fragment>
                {this.props.loading && <LoadScreen />}
                {this.props.cookies && <PopupCookies />}
                <NavMenu />
                <Container>
                    {this.props.children}
                </Container>
                <Footer />
            </React.Fragment>
        );
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Layout as any);