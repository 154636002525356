import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import { Modal } from 'reactstrap';
import APIController from '../helpers/APIController';
import PasswordValidator from '../helpers/PasswordValidator';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import AccountChangePassword from './AccountChangePassword';
import BaseComponent from './BaseComponent';
import QuestionWindow from './base/QuestionWindow';
import Grid from './base/Grid';
import List from './base/List';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

interface IAccountState {
    user: any | null
    showQuestionStopRenewingSubscription: boolean
    showQuestionDeleteAccount: boolean
    serverError: string | null
}


//class Login extends React.PureComponent<LoginProps> {
class Account extends BaseComponent<LoginProps, any> {
    state: Readonly<IAccountState> = {
        user: null,
        showQuestionStopRenewingSubscription: false,
        showQuestionDeleteAccount: false,
        serverError: null
    }

    public async componentDidMount() {
        await super.load(() => this.loadUser());
    }

    public renderChild() {
        return (
            <React.Fragment>
                {this.state.showQuestionStopRenewingSubscription && <QuestionWindow question="Czy na pewno przerwać subskrypcję?" action={() => { }} changeVisibility={() => { }} />}
                {this.state.showQuestionDeleteAccount && <QuestionWindow question="Czy na pewno usunąć konto?" action={() => this.deleteAccount()} changeVisibility={this.handleOnClickDeleteAccount.bind(this)} />}
                {
                    this.state.user &&

                    <div className="account-component">
                        {this.state.serverError && <p>{this.state.serverError}</p>}
                        <h1>Konto użytkownika</h1>
                        <div className="account-container">
                            <div className="account-column">
                                {this.renderInformations()}
                                {this.renderChagePassword()}
                            </div>
                            <div className="account-column">
                                {this.renderSubscriptions()}
                            </div>
                            <div className="account-column">
                                {this.renderOrders()}
                            </div>



                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

    private renderInformations(): React.ReactChild {
        return (
            <div className="account-item account_informations">
                <h2>Informacje</h2>
                <p>Adres email: {this.state.user.email}</p>
                <button onClick={() => this.changeNewsletter()}>{this.state.user.newsletter ? "Wypisz z newslettera" : "Zapisz do newslettera"}</button>
                <button onClick={() => this.handleOnClickDeleteAccount(true)}>Usuń konto</button>
            </div>
        )
    }

    private renderSubscriptions(): React.ReactChild {
        const gridFields = [
            {
                header: 'Słownik:',
                property: 'name',
                type: 'custom',
                custom: (entity: any) => { return <NavLink to={'/slowniki/' + entity.url}>{entity.name}</NavLink> }
            },
            {
                header: 'Data końca:',
                property: 'expirationDate',
                type: 'date',
            }
        ]
        const listButtons = [
            {
                name: 'Przedłuż subskrypcję',
                action: (entity: any) => { this.props.history.push("/zakupy/" + entity.dictionaryId) } 
            }
        ]

        return (
            <div className="account-item account-subscriptions">
                <h2>Subskrybcje</h2>
                {this.state.user.subscriptions && this.state.user.subscriptions.length > 0 && <List entities={this.state.user.subscriptions} fields={gridFields} buttons={listButtons} paging={{ perPage: 10 }} />}
            </div>
        )
    }

    private renderOrders(): React.ReactChild {
        const gridFields = [
            {
                header: 'Data:',
                property: 'orderDate',
                type: 'date'
            },
            {
                header: 'Słownik:',
                property: 'name',
                type: 'text'
            },
            {
                header: 'Dni:',
                property: 'subscriptionDays',
                type: 'text'
            },
            {
                header: 'Status',
                property: 'status',
                type: 'text'
            }
        ]

        const gridButtons = [
            {
                name: <span>Odśwież status płatności<i className= "fa fa-refresh" aria-hidden="true" ></i></span>,
                action: (entity: any) => this.handleOnClickRefreshOrderStatus(entity)
            }
        ]

        return (
            <div className="account-item account_orders">
                <h2>Zamówienia</h2>
                {this.state.user.orders && this.state.user.orders.length > 0 && <List entities={this.orders()} fields={gridFields} buttons={gridButtons} paging={{ perPage: 2 }} />}
            </div>
            )
    }

    private renderChagePassword(): React.ReactChild {
        return (
            <div className="account-item">
                <AccountChangePassword />
            </div>
            )
    }

    private orders(): any[] {
        let orders = this.state.user.orders;

        orders = orders.map((o : any) => {
            switch (o.status) {
                case 'paid': o.status = 'zapłacone'; break;
                case 'registered': o.status = 'zarejestrowane'; break;
                case 'recived_id': o.status = 'zarejestrowane w PayU'; break;
                case 'waiting': o.status = 'oczekujące na wpłatę'; break;
                case 'pending': o.status = 'przetwarzane przez PayU'; break;
                case 'canceled': o.status = 'anulowane'; break;
                case 'error': o.status = 'błąd'; break;
            }
            return o;
        })
        return orders;
    }


    //------- Handle region ---------//

    private async handleOnClickRefreshOrderStatus(order: any) {
        this.props.startLoad();
        await this.checkOrderStatus(order.orderId);
        this.props.endLoad();
    }

    private handleOnClickStopRenewingSubscription() {

    }

    private async changeNewsletter(): Promise<void> {
        const apiController: APIController = new APIController(this.props.token);
        try {
            await apiController.newsletter(this.state.user.email, !this.state.user.newsletter)
            const user = Object.assign({}, this.state.user);
            user.newsletter = !user.newsletter
            super.setState({
                user: user
            });
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    }

    private async checkOrderStatus(orderId: number): Promise<void> {
        const apiController: APIController = new APIController(this.props.token);
        try {
            const response = await apiController.checkOrderStatus(orderId);
            const user = Object.assign({}, this.state.user);
            const order = user.orders.find((o: any) => o.orderId == orderId);
            order.status = response.status;
            super.setState({
                user: user
            });
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    }

    private async stopRenewingSubscription(subscriptionId: number): Promise<void> {
        //TODO: okno z pytaniem

        const apiController: APIController = new APIController(this.props.token);
        try {
            await apiController.stopRenewingSubscription(subscriptionId);

            const user = Object.assign({}, this.state.user);
            const subscription = user.subscriptions.find((s: any) => s.subscriptionId == subscriptionId);
            subscription.renewing = false;
            super.setState({
                user: user
            });
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    }

    private handleOnClickDeleteAccount(showQuestion: boolean): void {
        this.setState({
            showQuestionDeleteAccount: showQuestion
        })
    }

    private async deleteAccount() {
        const apiController: APIController = new APIController(this.props.token);
        try {
            await apiController.deleteUser();
            this.props.logout();
        }
        catch (error : any) {
            this.setState({
                serverError: error.message
            })
        }
    }

    private async loadUser(): Promise<void>{
        try {
            const apiController: APIController = new APIController(this.props.token);
            const data = await apiController.getAccount();

            data.orders = data.orders.sort((o1: any, o2: any) => { return Number((new Date(o2.orderDate))) - Number((new Date(o1.orderDate))) });

            this.setState({
                user: data
            })
        }
        catch (error : any) {
            this.setState({
                serverError: error.message
            })
        }
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Account as any);
