import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import BaseComponent from './BaseComponent';
import SearchInput from './base/SearchInput';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import APIController from '../helpers/APIController';
import TranslatorPhrase from './TranslatorPhrase';
import Flag from './Flag';

type TranslatorProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

type TranslatorState = {
    phrases: any | null
    phrasesList: any | null
    selectedPhrase: any | null
    dictionaries: any | null
    selectedDictionary: string
    selectedLanguage: string
    secondLanguage: string
}

class HomeTranslator extends BaseComponent<TranslatorProps, TranslatorState> {

    state: Readonly<TranslatorState> = {
        phrases: null,
        phrasesList: null,
        selectedPhrase: {
            text: '',
            translations: [],
            definition: ''
        },
        dictionaries: null,
        selectedDictionary: '',
        selectedLanguage: '',
        secondLanguage: ''
    }

    constructor(props: any) {
        super(props);
        this.searchInput = React.createRef<typeof SearchInput>();
    }

    async componentDidMount() {
        this.props.startLoad();
        await this.loadDictionaries();
        this.props.endLoad();       
    }

    renderChild() {
        return (
            <div className="home-translator-container">
                {this.state.dictionaries && this.renderTranslator()}
                {this.state.selectedPhrase.text && <TranslatorPhrase {...this.props} phrase={this.state.selectedPhrase} selectedLanguage={this.state.selectedLanguage} secondLanguage={this.state.secondLanguage} />}
            </div>)
    }

    private renderTranslator(): React.ReactNode {
        return (
            <div className="home-translator">
                <h2><i className="fa fa-search" aria-hidden="true"></i>Szukaj hasła w słownikach </h2>
                <hr />
                {this.renderDictionarySelector()}
                {
                    this.state.phrases &&
                    <React.Fragment>

                        {
                            this.state.selectedDictionary &&
                            <div className="translator_languages">
                                <span><Flag {...this.props} size={"2x"} code={this.state.selectedLanguage} /></span>
                                <span onClick={() => this.handleOnClickSwitchLanguages()}><i className="fa fa-refresh" aria-hidden="true"></i></span>
                                <span><Flag {...this.props} size={'2x'} code={this.state.secondLanguage} /></span>
                            </div>
                        }

                        <SearchInput  {...this.props} items={this.phrasesSearchList()} selectedItem={this.state.selectedPhrase.text} changeSelectedItem={this.setSelectedPhrase.bind(this)} ref={this.searchInput} />
                    </React.Fragment>
                }
               
            </div>
        )
    }

    private renderDictionarySelector(): React.ReactNode {
        return (
            <select name="selectedDictionary" onChange={(e) => this.handleOnChangeSelectedDictionary(e)} value={this.state.selectedDictionary}> {//  >  //value={this.state.selectedDictionary ?? ''}>
            }
                <option key={''} value={''}>WYBIERZ SŁOWNIK</option>
                {
                    this.state.dictionaries.map((d: any, index: number) => <option key={index} value={d.dictionaryId}>{d.name}</option>)
                }
            </select>
        )
    }

    


    //----- HANDLE REGION -----//

    private async handleOnChangeSelectedDictionary(e: any): Promise<void> {
        console.log(this.state.selectedDictionary);
        console.log(e.target.value);

        this.setState({
            selectedPhrase: {
                text: ''
            },
            selectedDictionary: e.target.value,
            selectedLanguage: this.state.dictionaries.find((d: any) => d.dictionaryId == e.target.value).language1,
            secondLanguage: this.state.dictionaries.find((d: any) => d.dictionaryId == e.target.value).language2
        });
        await super.load(() => this.loadPhrases(e.target.value));
        this.searchInput.current.clear();
        
    }

    private async handleOnClickSwitchLanguages() {
        const selectedLanguage = this.state.secondLanguage
        const secondLanguage = this.state.selectedLanguage

        const dictionary = this.state.dictionaries.find((d: any) => d.dictionaryId == this.state.selectedDictionary)
        dictionary.phrases = this.state.phrases;
        const phrasesList = await super.load(() => this.preparePhrases(dictionary, selectedLanguage));

        this.setState({
            selectedPhrase: {
                text: ''
            },
            phrasesList: phrasesList,
            selectedLanguage: selectedLanguage,
            secondLanguage: secondLanguage
        })
        this.searchInput.current.clear();
    }

    private preparePhrases(dictionary: any, selectedLanguage: string): Array<any> {

        const language = selectedLanguage == dictionary.language1 ? "lang1" : "lang2";

        let phrasesList = dictionary.phrases.map((p: any) => {
            let phrases: any;

            if (language == "lang1") {
                phrases = p.translations1.map((pp: any) => {

                    return {
                        text: pp,
                        synonyms: p.translations1.filter((t: any) => t != pp),
                        translations: p.translations2,
                        definition: p.definition,
                        expressions: p.expressions
                    }
                })
            }
            else {
                phrases = p.translations2.map((pp: any) => {

                    return {
                        text: pp,
                        synonyms: p.translations2.filter((t: any) => t != pp),
                        translations: p.translations1,
                        definition: p.definition,
                        expressions: p.expressions
                    }
                })
            }
            return phrases;
        }
        )
        phrasesList = phrasesList.flat();
        const phrasesListDistinct: any = []
        for (const item of phrasesList) {
            if (phrasesListDistinct.some((p: any) => p.text == item.text) == false)
                phrasesListDistinct.push(item);
        }

        phrasesList = phrasesListDistinct.sort((a: any, b: any) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : ((b.text.toLowerCase() > a.text.toLowerCase()) ? -1 : 0));

        return phrasesList;
    }

    //private preparePhrases(dictionary: any, selectedLanguage: string): Array<any> {

    //    const language = selectedLanguage == dictionary.language1 ? "lang1" : "lang2";

    //    let phrasesList = dictionary.phrases.map((p: any) => {
    //        let phrases: any;

    //        if (language == "lang1") {
    //            phrases = p.translations1.map((pp: any) => {

    //                return {
    //                    text: pp,
    //                    translations: p.translations2,
    //                    definition: p.definition,
    //                    expressions: p.expressions
    //                }
    //            })
    //        }
    //        else {
    //            phrases = p.translations2.map((pp: any) => {

    //                return {
    //                    text: pp,
    //                    translations: p.translations1,
    //                    definition: p.definition,
    //                    expressions: p.expressions
    //                }
    //            })
    //        }
    //        return phrases;
    //    }
    //    )
    //    phrasesList = phrasesList.flat();
    //    const phrasesListDistinct: any = []
    //    for (const item of phrasesList) {
    //        if (phrasesListDistinct.some((p: any) => p.text == item.text) == false)
    //            phrasesListDistinct.push(item);
    //    }

    //    phrasesList = phrasesListDistinct.sort((a: any, b: any) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : ((b.text.toLowerCase() > a.text.toLowerCase()) ? -1 : 0));

    //    return phrasesList;
    //}

    private phrasesSearchList(): Array<string> {
        return this.state.phrasesList.map((p: any) => p.text);
    }

    private setSelectedPhrase(phrase: string) {
        let selectedPhrase;
        if (phrase)
            selectedPhrase = this.state.phrasesList.find((p: any) => p.text === phrase)
        else
            selectedPhrase = this.state.phrasesList[0];

        this.setState({
            selectedPhrase: selectedPhrase
        })
    }

    private async loadDictionaries(): Promise<void> {
        const api: APIController = new APIController(null);
        try {
            const dictionaries = await api.getDictionaries();
            this.setState({
                dictionaries: dictionaries,
                //selectedDictionary: dictionaries[0].dictionaryId,
                selectedLanguage: dictionaries[0].language1,
                secondLanguage: dictionaries[0].language2
            })
            //await this.loadPhrases(dictionaries[0].dictionaryId);
        }
        catch (ex) {

        }
    }

    private async loadPhrases(selectedDictionary: string) {

        if (this.props.logged) {
            try {
                const apiController: APIController = new APIController(this.props.token);
                const data = await apiController.getDictionary(parseInt(selectedDictionary));
                const phrasesList = this.preparePhrases(data, this.state.selectedLanguage)

                this.setState({
                    phrases: data.phrases,
                    phrasesList: phrasesList
                })
            }
            catch (e) {
                this.setState({
                    serverError: e
                })
            }
        }
        else {
            try {
                const apiController: APIController = new APIController(this.props.token);
                const data = await apiController.getDictionarySample(parseInt(selectedDictionary));
                const phrasesList = this.preparePhrases(data, this.state.selectedLanguage)

                this.setState({
                    phrases: data.phrases,
                    phrasesList: phrasesList
                })
            }
            catch (e) {
                this.setState({
                    serverError: e
                })
            }
        }
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(HomeTranslator as any);
