import * as React from 'react';
import { connect } from 'react-redux';
//import Iframe from 'react-iframe'
import { Link, NavLink, RouteComponentProps } from 'react-router-dom';
import BaseComponent from './BaseComponent';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import FlagIcon from './FlagIcon';
import APIController from '../helpers/APIController';
import { time } from 'console';
import Sorter from '../helpers/Sorter';
import DictionariesGrid from './DictionariesGrid';
import Select from 'react-select';
import Flag from './Flag';
import Dictionary from '../Dictionary';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

interface IDictionariesState {
    dictionaries: any | null
    languages: Array<string>
    filteredName: string
    filteredLanguageIndex: number
    sortMethod: number
}

type Sentence = {
    sentences: Array<string>,
    translations: Array<string> 
}

class Dictionaries extends BaseComponent<LoginProps, any>{
    state: Readonly<IDictionariesState> = {
        dictionaries: null,
        languages: [],
        filteredName: '',
        filteredLanguageIndex: 9999,
        sortMethod: 1
    }

    public async componentDidMount() {
        this.props.startLoad();
        await this.getDictionaries();
        await this.getLanguages();
        this.props.endLoad();
    }

    public renderChild() {
        return (

            this.state.dictionaries !== null &&

            <div className="dictionaries-container">
                {this.renderFilters()}
                <DictionariesGrid {...this.props} dictionaries={this.filteredDictionaries()} />
            </div>
        )
    }

    private renderFilters(): React.ReactNode {
        const languageOptions = this.state.languages.map((l: any, index: number) =>
        {
            return(
            {
                    value: index.toString(),
                    label: <div><span>{Dictionary.languagueShortcutToPolish(l)}</span><Flag {...this.props} code={l} size={'1x'} /></div>
            })
        })

        const sortOptions = [
            {
                value: 1,
                label: "A - Z"
            },
            {
                value: 2,
                label: "Z - A"
            }
        ]

        return (
            <div className="dictionaries-filters">
                <label>Nazwa<input name="filteredName" type="text" onChange={(e) => super.handleInputChange(e)} /></label>
                <label>Język
                {
                        this.state.languages.length > 0 &&
                        <Select
                            name={"filteredLanguageIndex"}
                            placeholder="Wybierz..."
                            //defaultValue={{ label: <div></div>, value: '0' }}
                            options={languageOptions}
                            isClearable={true }
                            onChange={(e) => this.handleFilteredLanguageChange(e)}
                        />
                               //<select name="filteredLanguageIndex" onChange={(e) => super.handleInputChange(e)} value={this.state.filteredLanguageIndex}>
                        //    <option value="9999"></option>
                        //    <React.Fragment>
                        //        {
                        //            this.state.languages.map((l: any, index: number) =>
                        //            /* <option key={index} value={index}>{this.languagueShortcutToPolish(l)}</option>*/
                        //                <option key={index} value={index}><img /></option>
                        //            )
                        //        }
                        //    </React.Fragment>
                        //</select>
                    }
                </label>
                <label>Sortowanie
                    <Select
                        placeholder="Wybierz"
                        options={sortOptions}
                        defaultValue={sortOptions[0]}
                        onChange={(e) => this.handleSortChange(e)}
                    />

                    {/*<select name="sortMethod" onChange={(e) => super.handleInputChange(e)} value={this.state.sortMethod}>*/}
                    {/*    <option value="1">A - Z</option>*/}
                    {/*    <option value="2">Z - A</option>*/}
                    {/*</select>*/}
                </label>
            </div>
        )
    }

    private handleFilteredLanguageChange(e: any) {
        this.setState({
            filteredLanguageIndex: e ? e.value : 9999
        })
    }

    private handleSortChange(e: any) {
        this.setState({
            sortMethod: e ? e.value : 9999
        })
    }

    private filteredDictionaries(): Array<any> {
        let dictionaries = this.state.dictionaries.filter((d: any) => { return true });

        if (this.state.filteredLanguageIndex < 9999)
            dictionaries = dictionaries.filter((d: any) => {
                return (d.language1 === this.state.languages[this.state.filteredLanguageIndex] || d.language2 === this.state.languages[this.state.filteredLanguageIndex] || d.flagCode == this.state.languages[this.state.filteredLanguageIndex])
            })
        if (this.state.filteredName)
            dictionaries = dictionaries.filter((d: any) => { return d.name.toLowerCase().includes(this.state.filteredName.toLowerCase()) });

        console.log('Metoda sortowania:' + this.state.sortMethod);

        //sortowanie
        const sorter: Sorter = new Sorter();
        if (this.state.sortMethod == 1)
            dictionaries = sorter.sortAlphabetically(dictionaries, 'name', false);
        if (this.state.sortMethod == 2)
            dictionaries = sorter.sortAlphabetically(dictionaries, 'name', true);



        return dictionaries;
    }

    private async getDictionaries(): Promise<void> {
        const apiController: APIController = new APIController(this.props.token);
        try {
            let response;
            if (this.props.logged)
                response = await apiController.getUserDictionaries()
            else
                response = await apiController.getDictionaries();
            this.setState({
                dictionaries: response
            })
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    }

    private async getLanguages(): Promise<void> {
        const apiController: APIController = new APIController(this.props.token);

        try {
            //const response = await apiController.getLanguages();
            const response = await apiController.getFlagCodes();
            response.push("pl");

            this.setState({
                languages: response
            })

            if (this.props.location.state.language) {
                const selectedLanguageIndex = response.indexOf(this.props.location.state.language);
                if (selectedLanguageIndex >= 0) {
                    this.setState({
                        filteredLanguageIndex: selectedLanguageIndex
                    })
                }
            }
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    } 


};

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Dictionaries as any);
