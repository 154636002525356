import * as React from 'react';
import { connect } from 'react-redux';
import { Route, RouteComponentProps, Router, Switch } from 'react-router';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import Home from './Home';
import Dictionary from './Dictionary';
import Dictionaries from './Dictionaries';
import Login from './Login';
import Register from './Register';
import Purchase from './Purchase';
import Account from './Account';
import RecoverPassword from './RecoverPassword';
import AdminPanel from './AdminPanel';
import AdminPanelUsers from './AdminPanelUsers';
import AdminPanelOrders from './AdminPanelOrders';
import AdminPanelSubscriptions from './AdminPanelSubscriptions';
import Payment from './Payment';
import AdminPanelDictionaries from './AdminPanelDictionaries';
import RegulationsBasic from './RegulationsBasic';
import RegulationsRODO from './RegulationsRODO';
import AdminPanelDictionary from './AdminPanelDictionary';
import AdminPanelConfiguration from './AdminPanelConfiguration';
import AdminPanelDictionaryPhrase from './AdminPanelDictionaryPhrase';
import DictionaryDescription from './DictionaryDescription';
import Contact from './Contact';
import AdminPanelSubscription from './AdminPanelSubscription';
import AdminPanelProducts from './AdminPanelProducts';
import AdminPanelProduct from './AdminPanelProduct';
import { Ukrainian, Ukrainian1000 } from './Ukrainian';
import AdminPanelPromoCodes from './AdminPanelPromoCodes';
import AdminPanelPromoCodesMass from './AdminPanelPromoCodesMass';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

class Routing extends React.PureComponent<LoginProps> {
    public render() {
        return (
            <React.Fragment>
                <Route exact path='/' component={Home} />
                <Route exact path='/slowniki' component={Dictionaries} />
                <Route path='/slowniki/start/:id' component={Dictionary} />
                <Route exact path='/slowniki/:id' component={DictionaryDescription} />
                <Route path='/logowanie' component={Login} />
                <Route path='/rejestracja' component={Register} />
                <Route exact path='/konto' component={this.props.logged ? Account : Login} />  {/*render={() => this.componentForLogged(<Account />)} />*/}
                <Route path='/przypomnij-haslo' component={RecoverPassword} />
                <Route path='/zakupy/:id' component={Purchase} />
                <Route path='/platnosc/:id' component={Payment} />
                <Route path='/kontakt' component={Contact} />
                <Route path='/regulamin' component={RegulationsBasic} />
                <Route path='/polityka-rodo' component={RegulationsRODO} />
                <Route exact path='/ua' component={Ukrainian} />
                <Route exact path='/ua/1000' component={Ukrainian1000} />
                <Route path='/panel' render={() => this.componentForAdmin(<AdminPanel />)} />
                <Route exact path='/panel/uzytkownicy' component={AdminPanelUsers} />
                <Route exact path='/panel/zamowienia' component={AdminPanelOrders} />
                <Route exact path='/panel/subskrypcje' component={AdminPanelSubscriptions} />
                <Route exact path='/panel/subskrypcje/:id' component={AdminPanelSubscription} />
                <Route exact path='/panel/slowniki' component={AdminPanelDictionaries} />
                <Route excat path='/panel/slowniki/:id' component={AdminPanelDictionary} />
                <Route exact path='/panel/konfiguracja' component={AdminPanelConfiguration} />
                <Route excat path='/panel/frazy/:id' component={AdminPanelDictionaryPhrase} />
                <Route excat path='/panel/produkty' component={AdminPanelProducts} />
                <Route excat path='/panel/produkt/:id' component={AdminPanelProduct} />
                <Switch>
                    <Route excat path='/panel/kody/dodawanie' component={AdminPanelPromoCodesMass} />
                    <Route excat path='/panel/kody' component={AdminPanelPromoCodes} />
                </Switch>
            </React.Fragment>
        );
    }

    componentForLogged(component: any) : any {
        return this.props.logged ? component : <Login />
    }

    componentForAdmin(component: any): any {
        return this.props.logged ?
            this.props.admin ?
                component
                :
                <Home />
            :
            <Login />
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Routing as any);