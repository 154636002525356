import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Button, Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import APIController from '../helpers/APIController';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;


interface ITotalPromoCodeState {
    promoKeyCode: string
    addComplete: boolean
    serverError: string
}

//class Login extends React.PureComponent<LoginProps> {
class TotalPromoCode extends BaseComponent<LoginProps, ITotalPromoCodeState> {
    state = {
        promoKeyCode: '',
        addComplete: false,
        serverError: ''
    }

    public render() {
        return (
            <div className="totalpromocodes">
                <h2>Wprowadź kod produktu</h2>
                {
                    this.props.logged ?
                        <React.Fragment>
                            <input name="promoKeyCode" value={this.state.promoKeyCode} onChange={(e) => super.handleInputChange(e)} />
                            <br />
                            <p><button onClick={() => this.addPromoCode()}>Wykorzystaj kod</button></p>
                        </React.Fragment>
                        :
                        <p><Link to='/logowanie'><button>Zaloguj się</button></Link> lub <Link to='rejestracja'><button> Zarejestruj się</button></Link></p>

                }
                {this.state.serverError && <p className="warning-message">{this.state.serverError}</p>}
                {this.state.addComplete && <p className="success-message">Poprawnie dodano subskrypcję</p>}
            </div>
        )
    }

    private async addPromoCode(): Promise<void> {
        const apiController: APIController = new APIController(this.props.token);
        try {
            let response = await apiController.useTotalPromoCode(this.state.promoKeyCode);
            super.setState({
                promoKeyCode: '',
                addComplete: true,
                serverError: ''
            })
        }
        catch (error: any) {
            console.log(error)
            super.setState({
                addComplete: false,
                serverError: error
            })
        }
    }

}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(TotalPromoCode as any);
