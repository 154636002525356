import * as React from 'react';
import { FlagIconSize } from 'react-flag-icon-css';
import { connect } from 'react-redux';
import FlagIcon from './FlagIcon';


export interface IFlagProps {
    code: string,
    size?: FlagIconSize
}


class Flag extends React.Component<any, any> {

    render() {
        let lang = this.props.code;
        if (lang === 'en')
            lang = 'gb';
        if (lang === 'it')
            lang = 'latinum';

        return (
            <React.Fragment>
                {
                    lang === "latinum" ?
                        <span className="flag-icon flag-icon-2x flag-icon-latinum"></span> :
                        <FlagIcon code={lang} size={this.props.size} />
                }
            </React.Fragment>
        )
    }
}

export default connect()(Flag as any);
