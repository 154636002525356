import * as React from 'react';
import { connect } from 'react-redux';
import APIController from '../helpers/APIController';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';


class RegulationsRODO extends BaseComponent<any, any>{

    state = {
        regulationsRODO: ''
    }

    public async componentDidMount() {
        this.props.startLoad();
        await this.getConfiguration();
        this.props.endLoad();
    }

    render() {
        return (
            <div dangerouslySetInnerHTML={{ __html: this.state.regulationsRODO }}>
            </div>
            )
    }

    private async getConfiguration(): Promise<void> {
        const apiController: APIController = new APIController(this.props.token);
        try {
            const response = await apiController.getConfiguration();
            this.setState({
                regulationsRODO: response.regulationsRODO
            })
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    }

}


export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(RegulationsRODO as any);
