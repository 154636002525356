import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import APIController from '../helpers/APIController';
import EmailValidator from '../helpers/EmailValidator';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

//class Login extends React.PureComponent<LoginProps> {
class RecoverPassword extends BaseComponent<LoginProps, any> {
    state = {
        email: '',

        emailEmpty: false,
        incorrectEmail: false,
        serverError: '',

        emailSent: false
    }


    public render() {
        return (
            <div>
                <h2>Odzyskiwanie hasła</h2>
                <div>
                    <label>Email</label><input className={`${this.state.emailEmpty ? "validation-warning" : ""}`} name="email" type="email" value={this.state.email} onChange={(e) => super.handleInputChange(e)} />
                    <button onClick={() => this.recoverPassword()}>Resetuj hasło</button>
                </div>
                {this.state.emailEmpty && <p className="warning-message">Nie podano adresu email</p>}
                {this.state.incorrectEmail && <p className="warning-message">Nieprawidłowy adres email</p>}
                {this.state.emailSent && <p>Pod wskazany adres email zostało wysłane nowe hasło</p>}
                {this.state.serverError && <p className="warning-message">{this.state.serverError}</p>}
            </div>
        );
    }

    private async recoverPassword(): Promise<void> {
        if (this.check()) {

            this.props.startLoad();
            const apiController: APIController = new APIController(this.props.token);
            try {
                await apiController.recoverPassword(this.state.email);
                this.setState({
                    email: '',
                    emptyEmail: false,
                    incorrectEmail: false,
                    emailSent: true
                })
            }
            catch (error : any) {
                super.setState({
                    serverError: error
                })
            }
            this.props.endLoad();
        }
    }

    private check(): boolean {
        const emailEmpty: boolean = !Boolean(this.state.email);
        let incorrectEmail: boolean = false;

        if (!emailEmpty)
            incorrectEmail = !EmailValidator.isEmailValid(this.state.email);

        this.setState({
            emailSent: false,
            emailEmpty: emailEmpty,
            incorrectEmail: incorrectEmail,
            serverError: ''
        });

        if (emailEmpty || incorrectEmail)           
            return false;
        else
            return true;
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(RecoverPassword as any);
