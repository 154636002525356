import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import APIController from '../helpers/APIController';
import EmailValidator from '../helpers/EmailValidator';
import PasswordValidator from '../helpers/PasswordValidator';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';
import LoadScreen from './LoadScreen';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;


class Payment extends BaseComponent<LoginProps, any>{
    state = {
        paymentStatus: '',
        registerStatus: '',
        refId: ''
    }

    async componentDidMount() {
        const search: string = window.location.search;
        const params = new URLSearchParams(search);
        const statusCode = params.get("statusCode");
        const refReqId = params.get("refReqId");

        if (statusCode === "WARNING_CONTINUE_CVV") {
            //wyciągnięcie wartości 
        }

        this.setState({
            registerStatus: statusCode,
            refId: refReqId
        })

        if (this.props.match.params.id)
            await this.checkOrderStatus();

    }

    public render() {
        return (
            <div>
                <h1>Płatność</h1>
                <p>Status płatności: {this.state.paymentStatus}</p>
                <p>RefId: {this.state.refId}</p>
                {
                    this.state.paymentStatus ?
                        <p>{this.state.paymentStatus}</p>
                        :
                        <p>Oczekiwanie na potwierdzienie płatości...</p>
                }
            </div>
        )
    }

    private async checkOrderStatus() {
        const apiController: APIController = new APIController(this.props.token);
        try {
            const data = await apiController.getOrder(this.props.match.params.id);
            this.setState({
                paymentStatus: data.status
            })
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    }


}


export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Payment as any);
