import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import DictionarySpeechController from '../helpers/DictionarySpeechController';
import SpeechController from '../helpers/SpeechController';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';
import NumberToUpperIndexConverter from './NumberToUpperIndexConverter';

type TranslatorPhraseProps = {
    selectedLanguage: string
    secondLanguage: string
    phrase: any | null
}


class TranslatorPhrase extends BaseComponent<TranslatorPhraseProps, any>{
    state = {
        speechSelectedLanguageAvailable: false,
        speechSecondLanguageAvailable: false
    }

    async componentDidMount() {
        await this.SetLanguageAvailableStates();
    }

    async componentDidUpdate(props: TranslatorPhraseProps) {
        await this.SetLanguageAvailableStates();
    }

    private async SetLanguageAvailableStates(): Promise<void> {
        const selectedLanguageAvailable = await this.checkSpeechLanguageAvailable(this.props.selectedLanguage);
        const secondLanguageAvailable = await this.checkSpeechLanguageAvailable(this.props.secondLanguage);
        console.log(selectedLanguageAvailable)
        this.setState({
            speechSelectedLanguageAvailable: selectedLanguageAvailable,
            speechSecondLanguageAvailable: secondLanguageAvailable
        })
    }
 

    render() {

        return (
            <div className="phrase-container">
                <div className="phrase-text"><NumberToUpperIndexConverter {...this.props} text={this.props.phrase.text} />
                    {this.state.speechSelectedLanguageAvailable && <i className="fa fa-volume-up" aria-hidden="true" onClick={() => this.readPhrase(this.props.phrase.text, this.props.selectedLanguage)}></i>}
                </div>
                {
                    this.props.phrase.translations && this.props.phrase.translations.length ?
                        <React.Fragment>

                            {
                                this.props.phrase.synonyms.length > 0 &&
                                <p className="phrase_synonyms">
                                    {
                                        //this.checkLanguageAvailable(this.props.selectedLanguage) &&
                                        this.props.phrase.synonyms.map((s: any) =>
                                            <span>
                                                {s}
                                                {this.state.speechSelectedLanguageAvailable && <i className="fa fa-volume-up" aria-hidden="true" onClick={() => this.readPhrase(s, this.props.selectedLanguage)}></i>}
                                            </span>
                                        )
                                    }
                                </p>
                            }
                            <p className="phrase_translations">
                                {
                                    
                                    this.props.phrase.translations.map((t: any) =>
                                        <span>
                                            {t}
                                            {this.state.speechSecondLanguageAvailable && <i className="fa fa-volume-up" aria-hidden="true" onClick={() => this.readPhrase(t, this.props.secondLanguage)}></i>}
                                        </span>
                                    )
                                }

                            </p>
                            <p className="phrase_definition">{this.props.phrase.definition}</p>
                            {
                                this.props.phrase.expressions &&
                                <p className="phrase_expressions">
                                    <ul>
                                    {
                                        this.props.phrase.expressions.map((e: any, i: number) =>
                                            <li key={i}>{e}</li>
                                        )
                                    }
                                    </ul>
                                </p>
                            }
                        </React.Fragment>
                        :
                        <div>
                            <p>Zwrot zawarty w słowniku dla zarejestrowanych użytkowników</p>
                            <NavLink to="/rejestracja"><button>Uzyskaj dostęp</button></NavLink>
                        </div>

                }

            </div>
        )
    }

    private readPhrase(phrase: string, language: string): void {
        const speechController: DictionarySpeechController = new DictionarySpeechController();
        speechController.readPhrase(phrase, language);
    }

    private async checkSpeechLanguageAvailable(language: string): Promise<boolean> {
        const speechController: DictionarySpeechController = new DictionarySpeechController();
        let speechLanguageAvailable = await speechController.checkLanguageAvailable(language);
        return speechLanguageAvailable;
    }
}
   


export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(TranslatorPhrase as any);
