import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import APIController from '../helpers/APIController';
import BaseComponent from './BaseComponent';
import Flag from './Flag';
import * as LoginStore from '../store/Login';
import { ApplicationState } from '../store';
import { NavLink } from 'react-router-dom';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

type DictionaryDescriptionState = {
    dictionary: any | null,
    serverError: string | null

}

class DictionaryDescription extends BaseComponent<LoginProps, DictionaryDescriptionState> {
    state : Readonly<DictionaryDescriptionState>  = {
        dictionary: null,
        serverError: null
    }


    async componentDidMount() {
        this.props.startLoad();
        await this.getDictionary();
        this.props.endLoad();
    }

    render() {
        return (
            
            this.state.dictionary &&

            <div className="dictionary-description">
                <div className="dictionary-description-header">
                    <div className="dictionary-description-header-element">
                        <h1><b>{this.state.dictionary.name}</b></h1>
                        <div className="dictionary-description-buttons">
                            {
                                (this.props.logged && this.state.dictionary.hasSubscription) ?
                                    <NavLink to={'/slowniki/start/' + this.state.dictionary.dictionaryId}><button>Otwórz</button></NavLink>
                                    :
                                    <NavLink to={'/zakupy/' + this.state.dictionary.dictionaryId}><button>Uzyskaj dostęp</button></NavLink>
                            }
                        </div>
                    </div>
                    <div className="dictionary-description-header-element">
                        <img src={this.state.dictionary.image} />
                    </div>
                </div>
                <p dangerouslySetInnerHTML={{ __html: this.state.dictionary.description }} />
                <div className="dictionary-item_flags">
                    <Flag {...this.props} size={"2x"} code={this.state.dictionary.language1} />
                    <Flag {...this.props} size={"2x"} code={this.state.dictionary.language2} />
                </div>
               
            </div>    
        )
    }

    private async getDictionary(): Promise<void> {
        try {
            const apiController: APIController = new APIController(this.props.token);
            const data = await apiController.getDictionaryByUrl(this.props.match.params.id);
            this.setState({
                dictionary: data
            })
        }
        catch (e) {
            this.setState({
                serverError: e
            })
        }
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(DictionaryDescription as any);
