import * as React from 'react';
import { connect } from 'react-redux';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

class NavMenu extends React.PureComponent<LoginProps, { isOpen: boolean }> {
    public state = {
        isOpen: false
    };

    public render() {
        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm box-shadow mb-3" light>
                    <Container>
                        <NavbarBrand tag={Link} to="/">
                            <img className="nav-logo" src="./img/logo.jpg" />
                        </NavbarBrand>
                        <NavbarToggler onClick={this.toggle} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={this.state.isOpen} navbar>
                            <ul className="navbar-nav flex-grow">
                                <NavItem>
                                    <NavLink tag={Link} className="text-white" to="/slowniki"><i className="fa fa-book" aria-hidden="true"></i><span>Słowniki</span></NavLink>
                                </NavItem>

                                {
                                    this.props.logged ?
                                        <React.Fragment>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-white" to="/konto"><i className="fa fa-user" aria-hidden="true"></i><span>Konto</span></NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-white" onClick={() => this.props.logout()}><i className="fa fa-sign-out" aria-hidden="true"></i><span>Wyloguj</span></NavLink>
                                            </NavItem>
                                        </React.Fragment>
                                        :
                                        <React.Fragment>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-white" to="/logowanie"><i className="fa fa-sign-in" aria-hidden="true"></i><span>Zaloguj</span></NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink tag={Link} className="text-white" to="/rejestracja"><i className="fa fa-pencil" aria-hidden="true"></i><span>Zarejestruj</span></NavLink>
                                            </NavItem>
                                        </React.Fragment>
                                }
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(NavMenu as any);
