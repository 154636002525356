import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;


interface ILoginState {
    email: string,
    password: string,

    emailEmpty: boolean,
    passwordEmpty: boolean
    serverError: string
}

//class Login extends React.PureComponent<LoginProps> {
class Login extends BaseComponent<LoginProps, ILoginState> {
    state = {
        email: '',
        password: '',

        emailEmpty: false,
        passwordEmpty: false,
        serverError: ''
    }

    public render() {
        return (
            this.props.logged ?

                <Redirect to='/' />

                :

                <div className="login-container">
                    <h1>Logowanie</h1>
                    <label>Email</label><input className={`${this.state.emailEmpty ? "validation-warning" : ""}`} name="email" type="email" value={this.state.email} onChange={(e) => super.handleInputChange(e)} />
                    {this.state.emailEmpty && <p className="warning-message">Nie podano adresu email</p>}
                    <label>Hasło</label><input className={`${this.state.passwordEmpty ? "validation-warning" : ""}`} name="password" type="password" value={this.state.password} onChange={(e) => super.handleInputChange(e)} />
                    {this.state.passwordEmpty && <p className="warning-message">Nie podano hasła</p>}
                    <button onClick={() => this.login()}>Zaloguj</button>
                    {this.props.loginError && <p className="warning-message">Login lub hasło niepoprawne</p>}
                    <p>Nie posiadasz jeszcze konta? <Link to='/rejestracja'>Zarejestruj się</Link></p>
                    <p><Link to='przypomnij-haslo'>Nie pamiętam hasła</Link></p>
                </div>
        );
    }

    private async login(): Promise<void> {
        if (this.check()) {
            this.props.startLoad();
            await this.props.login(this.state.email, this.state.password);
        }
    }

    private check(): boolean {
        const emailEmpty: boolean = !Boolean(this.state.email);
        const passwordEmpty: boolean = !Boolean(this.state.password);

        if (emailEmpty || passwordEmpty) {
            this.setState({
                emailEmpty: emailEmpty,
                passwordEmpty: passwordEmpty,
            })
            return false;
        }
        else
            return true;

    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Login as any);
