import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Redirect, RouteComponentProps } from 'react-router';
import BaseComponent from './BaseComponent';
import FlagIcon from './FlagIcon';
import SearchInput from './base/SearchInput';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import APIController from '../helpers/APIController';
import SpeechController from '../helpers/SpeechController';
import Newsletter from './Newsletter';
import HomeTranslator from './HomeTranslator';
import Dictionaries from './Dictionaries';
import DictionariesGrid from './DictionariesGrid';
import Dictionary from '../Dictionary';
import Flag from './Flag';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserNurse, faUserMd, faHammer } from '@fortawesome/free-solid-svg-icons'
import TotalPromoCode from './TotalPromoCode';

type LoginProps = {

}


type HomeState = {
    dictionaries: Array<any> | null
    siteDescription: string
    freeVersion: boolean
    promoKeyCode: string
}

class Home extends BaseComponent<LoginProps, HomeState> {

    state: Readonly<HomeState> = {
        dictionaries: null,
        siteDescription: '',
        freeVersion: false,
        promoKeyCode: ''
    }

    public async componentDidMount() {
        this.props.startLoad();
        //super.load(() => {
        await this.getDictionaries();
        await this.getConfiguration();
        //});
        this.props.endLoad();
    }

    renderChild() {
        return (
            <div className="home-container">

                {this.state.freeVersion && this.renderTestVersionInfo()}
                <TotalPromoCode />

                <div className="home-element">
                    <div className="home-description-2">
                        <h3 style={{ fontWeight: 600, color: 'rgb(249,255,0)' }}>DICTI.EU</h3>
                        <motion.h2
                            style={{ fontSize: 25, fontWeight: 700 }}
                            initial={{ y: -100 }}
                            animate={{ y: 0 }}
                            transition={{ duration: 1 }}
                        >
                            <p>SŁOWNIKI DLA</p>
                            <p><span style={{ color: 'rgb(249,255,0)' }}>PROFESJONALISTÓW</span></p>
                            <p>ONLINE</p>
                        </motion.h2>
                        <motion.div
                            initial={{ y: 100 }}
                            animate={{ y: 0 }}
                            transition={{ duration: 1 }}
                            dangerouslySetInnerHTML={{ __html: this.state.siteDescription }}>
                        </motion.div>
                    </div>

                    <div className="home-proses">
                        <p className="home-pros"><i className="fa fa-language" aria-hidden="true"></i> Języki
                        <motion.span
                                className="home-pros_number"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: .5 }}>
                                {this.getLanguages().length}
                        </motion.span>
                        </p>
                        <p className="home-pros"><i className="fa fa-book" aria-hidden="true"></i> Słowniki
                        <motion.span
                                className="home-pros_number"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: .5, duration: .5 }}>
                                15
                                {/*{this.state.dictionaries && this.state.dictionaries?.length}*/}
                        </motion.span>
                        </p>
                        <p className="home-pros"><i className="fa fa-bookmark" aria-hidden="true"></i> Hasła
                        <motion.span
                                className="home-pros_number"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ delay: 1, duration: .5 }}>
                                226 455
                                {/*{this.state.dictionaries && this.state.dictionaries?.reduce(function (previousValue, currentValue) {*/}
                                {/*    return previousValue + currentValue['phrasesCount'];*/}
                                {/*}, 0)}*/}
                        </motion.span>
                        </p>
                    </div>
                </div>

                <div className="home-branches">
                    <h2>Branże</h2>
                    <hr />
                    <div className="home-branches_container">
                        <div className="home-branch"><img src="/img/doctor.jpg" /><span>Słowniki medyczne</span></div>
                        <div className="home-branch"><img src="/img/nurse.jpg" /><span>Słowniki dla pielęgniarek i rehabilitantów</span></div>
                        <div className="home-branch"><img src="/img/builder.jpg" /><span>Słowniki budowlano-techniczne</span></div>
                    </div>
                </div>

                {/* {this.renderHomeDescritpion()}*/}


                <HomeTranslator />

                {/*<div className="home-items">*/}
                {/*    <div className="home-item">*/}
                {/*        <div className="home-item_block">*/}
                {/*            <i className="fa fa-book" aria-hidden="true"></i>*/}
                {/*            <p>Liczba słowników</p>*/}
                {/*        </div>*/}
                {/*        <span>???</span>*/}
                {/*    </div>*/}

                {/*    <div className="home-item">*/}
                {/*        <div className="home-item_block">*/}
                {/*            <i className="fa fa-quora" aria-hidden="true"></i>*/}
                {/*            <p>Liczba słów</p>*/}
                {/*        </div>*/}
                {/*        <span>???</span>*/}
                {/*    </div>*/}

                {/*    <div className="home-item">*/}
                {/*        <div className="home-item_block">*/}
                {/*            <i className="fa fa-user" aria-hidden="true"></i>*/}
                {/*            <p>Liczba użytkowników</p>*/}
                {/*        </div>*/}
                {/*        <span>12 234</span>*/}
                {/*    </div>*/}
                {/*</div>*/}

                {this.state.dictionaries && this.renderDictionaries()}
                {this.state.dictionaries && this.renderLanguages()}

                {/*{this.renderUkrainian()}*/}

                <Newsletter />


            </div>)
    }

    private renderHomeDescritpion(): React.ReactChild {
        return (
            <div className="home-description">
                <div dangerouslySetInnerHTML={{ __html: this.state.siteDescription }}></div>
            </div>
            )
    }

    //private renderTotalPromoCodeBox(): React.ReactChild {
    //    return (
    //        <div>
    //            <h2>Wprowadz kod promocyjny</h2>
    //            <input name="promoKeyCode" value={this.state.promoKeyCode} onChange={(e) => super.handleInputChange(e)}/>
    //            <button onClick={() => this.addPromoCode()}>Wykorzystaj kod</button>
    //        </div>
    //        )
    //}

    //private async addPromoCode(): Promise<void> {
    //    const apiController: APIController = new APIController(this.props.token);
    //    try {
    //        let response = await apiController.useTotalPromoCode(this.state.promoKeyCode);
    //        //this.setState({
    //        //    dictionaries: response
    //        //})
    //    }
    //    catch (error: any) {
    //        super.setState({
    //            serverError: error.message
    //        })
    //    }
    //}


    private renderUkrainian(): React.ReactChild {
        return (
            <div className="home-ukrainian" style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                columnGap: '1em',
                rowGap: '1em',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                textAlign: 'center',
                padding: '1.5em 1.5em 1.5em 1.5em'
            }}>
                <div style={{}}>
                    <h2 style={{ color: 'rgb(249,255,0)' }}>УКРАЇНСЬКО-ПОЛЬСЬКИЙ РОЗМОВНИК</h2>
                    <p>Вивчайте найпопулярніші фрази разом з їхньою вимовою</p>
                    <h2 style={{ color: 'rgb(249,255,0)', fontSize: 24 }}><b>ROZMÓWKI UKRAIŃSKO-POLSKIE</b></h2>
                    <p style={{ fontSize: 18 }}>Poznaj popularne zwroty wraz w wymową</p>
                    <img src="/img/rozmowki_pl_ua.png" />
                    {/*<img src="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/4.1.4/flags/4x3/ua.svg" style={{ border: '1px solid black' }} />*/}
                </div>
                <div style={{ minWidth: 300, width: '50%' }}>

                    
                    <div style={{ fontSize: 12 }}>
                    <p>Ласкаво просимо до Польщі!</p>
                    <p>Незалежно від того, чи Ви плануєте затриматись у Польщі на короткий час, чи залишитись тут на довше, наш розмовник полегшить Вам спілкування в нашій країні, тому нехай він завжди буде поряд із Вами!</p>
                    <p>З нашим словничком Ви дізнаєтеся: алфавіт, цифри, кольори і форми; взнаєте як придбати квиток на автобус, записатися на прийом до лікаря, запитати про дорогу та вирішити багато інших щоденних питань.</p>
                    <p>Також ми підкажемо Вам на які слова варто звернути увагу, адже хоча вони звучать однаково як польською, так і українською, вони означають різні речі :)</p>
                    <p>Приємного перебування! </p>
                    </div>
                    <NavLink to="/ua"><button style={{ backgroundColor: 'rgb(249,255,0)', color: 'blue' }}><b>РОЗМОВНИК / ROZMÓWKI </b></button></NavLink>
                    {/*<p></p>*/}
                    {/*<NavLink to="/ua/1000"><button style={{ backgroundColor: 'rgb(249,255,0)', color: 'blue' }}><b>1000 СЛОВА / 1000 SŁÓWEK</b></button></NavLink>*/}
                </div>
            </div>
        )
    }

    private renderTestVersionInfo(): React.ReactChild {
        return (
            <div className="home-test-version-info">
                <h2>! Wersja testowa !</h2>
                <p>Serwis DICTI.EU działa obecnie w wersji testowej, dzięki czemu jest obecnie całkowicie darmowy dla zalogowanych użytkowników! Informacje o ewentualnych błędach prosimy kierować na adres: <a href="mailto: kontakt@dicti.eu">kontakt@dicti.eu</a></p>
            </div>
            )
    }

    private renderDictionaries(): React.ReactChild {
        return (
            <div className="home-dictionaries">
                <div className="home-dictionaries-header">
                    <h2>Nasze słowniki</h2>
                </div>
                <DictionariesGrid {...this.props} dictionaries={this.state.dictionaries} />
                <NavLink to="/slowniki"><button>Zapoznaj się z pełną ofertą słowników</button></NavLink>
            </div>
        )
    }

    private renderLanguages(): React.ReactChild {
        let languages = this.getFlagCodes();

        if (languages) {

            return (
                <div className="home-languages-container">
                    <h2>Języki</h2>
                    <hr />
                    <div className="home-languages">
                        {
                            languages.map((l: string) =>

                                <NavLink to={{ pathname: '/slowniki', state: { language: l } }}>
                                    <div className="flagicon-container">
                                        <Flag {...this.props} code={l} size={'2x'} />
                                        <span>{Dictionary.languagueShortcutToNative(l)}</span>
                                        <span>{Dictionary.languagueShortcutToPolish(l)}</span>
                                    </div>
                                </NavLink>
                            )
                        }
                    </div>
                </div>
            )
        }
        else
            return <React.Fragment></React.Fragment>
    }

    private async getDictionaries(): Promise<void> {
        const apiController: APIController = new APIController(this.props.token);
        try {
            let response = await apiController.getDictionaries();
            this.setState({
                dictionaries: response
            })
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    }

    private async getConfiguration(): Promise<void> {
        const apiController: APIController = new APIController(this.props.token);
        try {
            const response = await apiController.getConfiguration();
            this.setState({
                siteDescription: response.siteDescription,
                freeVersion: response.freeVersion
            })
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    }

    private getFlagCodes(): Array<string> {
        if (this.state.dictionaries) {
            let flagCodes = this.state.dictionaries.filter(d => d.flagCode).map((d: any) => { return d.flagCode });
            flagCodes = Array.from(new Set(flagCodes));
            return flagCodes;
        }
        else
            return [];
    }

    private getLanguages(): Array<string> {
        if (this.state.dictionaries) {

            const languages1 = this.state.dictionaries.map((d: any) => { return d.language1 });
            const languages2 = this.state.dictionaries.map((d: any) => { return d.language2 });
            let languages = languages1.concat(languages2).filter((value, index, self) => self.indexOf(value) === index);
            return languages;
        }
        else
            return [];
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Home as any);
