import * as React from 'react';
import Layout from './components/Layout';
import './custom.css'
import './main.css'
import Routing from './components/Routing';

export default () => (
    <Layout>
        <Routing />
    </Layout>
);
