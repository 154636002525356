import * as React from 'react';
import { connect } from 'react-redux';
import APIController from '../helpers/APIController';
import BaseComponent from './BaseComponent';


type FooterState = {
    buildDate: string | null
    contactInformations: string
    contactEmail: string
    serverError: string
}

class Footer extends React.Component<any, FooterState> {
    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className="footer-item">
                        <h4>Informacje</h4>
                        <a href="/kontakt">Kontakt</a>
                        <a href="/regulamin">Regulamin</a>
                        <a href="/polityka-rodo">Polityka prywatności</a>
                    </div>
                    <div className="footer-item footer-social-media">
                        <h4>Społeczność</h4>
                        <a href="https://www.facebook.com/dicti.eu"><i className="fa fa-facebook-square" aria-hidden="true"></i></a>
                    </div>
                    <div className="footer-item">
                        <div className="footer-logo">
                            <a href="/"><img src="img/logo.jpg" /></a>
                            <p>Wszelkie prawa zastrzeżone</p>
                            <p>© DICTI.EU</p>
                        </div>
                    </div>

                </footer >
            </React.Fragment>
        )
    }
}

export default connect()(Footer as any);
