import { supportsGoWithoutReloadUsingHash } from 'history/DOMUtils';
import { request } from 'https';
import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { Link, NavLink } from 'react-router-dom';
import APIController from '../helpers/APIController';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';

type PurchaseState = {
    dictionaryName: string
    period: string,
    serverError: string,
    products: any | null,
    showCardForm: boolean,
    selectedProduct: number | null,
    selectedPayMethod: string | null
    cardOwnerFirstName: string
    cardOwnerSecondName: string
    promoCode: string
    invalidPromoCode: boolean
    addedPromoCode: any | null
    tokenizeError: string | null
    orderAlreadyExistsError: boolean
    isRegulationAccepted: boolean
    noRegulationAccepted: boolean
}

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

class Purchase extends BaseComponent<LoginProps, any> {
    state: Readonly<PurchaseState> = {
        dictionaryName: '',
        period: '',
        serverError: '',
        selectedProduct: null,
        selectedPayMethod: 'pbl',
        showCardForm: true,
        products: null,
        cardOwnerFirstName: '',
        cardOwnerSecondName: '',
        promoCode: '',
        invalidPromoCode: false,
        addedPromoCode: null,
        tokenizeError: null,
        orderAlreadyExistsError: false,
        isRegulationAccepted: false,
        noRegulationAccepted: false
    }

    /*payuSdkForms: payu.PayU | null = null //= //PayU('393823');*/

    async componentDidMount() {
        await super.load(() => this.loadProducts());
        await super.load(() => this.prepareCardForm());
    }

    public renderChild() {
        return (

            this.props.logged ?

                <div className="purchase">
                    <h3>{this.state.dictionaryName}</h3>
                    <hr />
                    {this.state.products && this.renderProducts()}
                    <hr />
                    {this.renderPayMethod()}
                    <hr />
                    {this.renderPromoCode()}
                    <hr />
                    {this.renderRegulations()}
                    {this.state.noRegulationAccepted && <p className="validation-warning">Nie zaakceptowano regulaminu</p>}
                           
                    {/*      {this.renderCardForm()}*/}
                    <button onClick={() => this.subscribePayU()}>Subskrybuj</button>
                    {this.state.orderAlreadyExistsError && <p>Istnieje niedokończone zamówienie na wybrany słownik. Dokończ zamówienie z poziomu konta użytkownika</p>}
                    {this.state.serverError && <p>{this.state.serverError}</p>}
                </div>

                :

                <Redirect to='/logowanie' />
        );
    }

    private renderProducts(): React.ReactNode {

        return (
            <div className="purchase-form">
                <h3>Warianty subskrypcji</h3>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    columnGap: '1em'
                }}>
                    {
                        this.state.products.map((p: any, index: number) =>
                            <div key={index} className="purchase-form_product" style={{
                                
                            }}>
                                <input type="radio" id={"radio" + p.productId} name="selectedProduct" value={p.productId} checked={this.state.selectedProduct == p.productId} onChange={(e) => this.handleOnChangeSelectedProduct(e)} />
                                <label htmlFor={"radio" + p.productId}>
                                    {p.subscriptionDays} dni za
                                    <span> </span>
                                    {this.state.addedPromoCode && <span style={{ textDecorationLine: 'line-through', marginRight: '.5em' }}>{parseFloat(p.price).toFixed(2)}</span>}
                                    {this.state.addedPromoCode ? (parseFloat(p.price) * (1 - this.state.addedPromoCode.value)).toFixed(2) : parseFloat(p.price).toFixed(2)}
                                    <span> zł</span> 
                                    
                                 </label><br />
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }

    private renderRegulations(): React.ReactNode {
        return (
            <div>
                <label className="label-checkbox"><input className={`${this.state.noRegulationAccepted ? "validation-warning" : ""}`} name="isRegulationAccepted" type="checkbox" checked={this.state.isRegulationAccepted} onChange={(e) => { super.handleInputChange(e); this.setState({noRegulationAccepted: false}) }} /> * Akceptuję <NavLink {...this.props} tag={Link} to="/regulamin">regulamin</NavLink> serwisu i zapoznałem się z informacjami dotyczącymi <NavLink {...this.props} tag={Link} to="/polityka-rodo">polityki prywantości</NavLink></label>
            </div>
            )
    }

    private renderPayMethod(): React.ReactNode {
        return (

            
            <div className="pay-method-container">
                <h3>Metoda płatności</h3>
                {/*<div className="purchase-form_product">*/}
                {/*    <input type="radio" name="selectedPayMethod" value="card" checked={this.state.selectedPayMethod === "card"} onChange={(e) => this.handleOnChangePaymentMethod(e)} />*/}
                {/*    <label>Płatność kartą</label>*/}
                {/*</div>*/}
                {
/*                    this.state.selectedProduct && this.state.products.find((p: any) => p.productId == this.state.selectedProduct).subscriptionDays == "365" &&*/
                    <div className="purchase-form_product">
                        <label style={{ display: 'flex', columnGap: '1em', alignItems: 'center' }}><input type="radio" name="selectedPayMethod" value="pbl" checked={this.state.selectedPayMethod === "pbl"} onChange={(e) => this.handleOnChangePaymentMethod(e)} />
                            Przelew natychmiastowy PayU <img src="https://poland.payu.com/wp-content/themes/global-website/assets/src/images/payu-logo.svg" style={{ maxWidth: 40, width: 40, height: 30 }} /></label>
                    </div>
                }
                
            </div>
            )
    }

    private renderPromoCode(): React.ReactNode {
        return (
            <div>
                <h3>Kod promocyjny</h3>
                <input
                    disabled={this.state.addedPromoCode ? true: false}
                    type="text"
                    name="promoCode"
                    onChange={(e) => { this.setState({ invalidPromoCode: false }); this.handleInputChange(e) }}
                    style={{
                    width: 75,
                    height: 38,
                    fontSize: 20,
                        textAlign: 'center',
                        backgroundColor: this.state.addedPromoCode ? 'lightgreen' : (this.state.invalidPromoCode ? 'pink' : 'white')
                }} />
                <button disabled={this.state.addedPromoCode ? true : false} onClick={() => this.addPromoCode()}>Dodaj</button>
            </div>
        )
    }

    private async addPromoCode(): Promise<void> {
        //sprawdzenie poprawności kodu
        try {
            const apiController = new APIController(this.props.token);
            let promoCodeObject = await apiController.checkPromoCode(this.state.promoCode, this.state.selectedProduct ? this.state.selectedProduct : 0, false);
            this.setState({
                addedPromoCode: promoCodeObject
            })
        }
        catch {
            this.setState({
                invalidPromoCode: true
            })
        }
    }

    private renderCardForm(): React.ReactNode {
        return (
            <div className="card-container" style={{ visibility: this.state.showCardForm ? "visible" : "hidden" }}>
                <label>Imię <input type="text" name="cardOwnerFirstName" value={this.state.cardOwnerFirstName} onChange={(e) => super.handleInputChange(e)} /></label>
                <label>Nazwisko <input type="text" name="cardOwnerSecondName" value={this.state.cardOwnerSecondName} onChange={(e) => super.handleInputChange(e)} /></label>
                <div className="payu-card-form" id="payu-card"></div>
                {this.state.tokenizeError && <p>{this.state.tokenizeError}</p>}              
            </div>
        )
    }

    private async handleOnChangeSelectedProduct(e: React.ChangeEvent<HTMLInputElement>) {
        e.persist();

        const selectedProductId = e.currentTarget.value;

        //if (this.state.products.find((p: any) => p.productId == selectedProductId).subscriptionDays != "365") {
            
        //    this.setState({
        //        selectedPayMethod: 'card',
        //        showCardForm: true
        //    })
        //}

        super.handleInputChange(e);
    }

    private async handleOnChangePaymentMethod(e: React.ChangeEvent<HTMLInputElement>) {
        //e.persist();  
        //this.setState({
        //    showCardForm: e.currentTarget.value === 'card'
        //})
        //super.handleInputChange(e);
        
    }

    private async prepareCardForm(): Promise<void> {
        const apiController: APIController = new APIController(this.props.token);
        try {
            const response = await apiController.getPayUMerchantId();
            if (this.payuSdkForms) {
                const secureForms = this.payuSdkForms.secureForms();
                //utworzenie formularza podając typ oraz opcje
                const card = secureForms.add('card');
                //renderowanie formularza
                card.render('#payu-card');
            }
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }

       
    }

    private async loadProducts(): Promise<void> {

        const apiController: APIController = new APIController(this.props.token);
        try {
            const response = await apiController.getDictionaryProducts(this.props.match.params.id)
            super.setState({
                dictionaryName: response.dictionaryName,
                products: response.products,
                selectedProduct: response.products[0].productId
            });
        }
        catch (error : any) {
            super.setState({
                serverError: error.message
            })
        }
    }

    private validate(): boolean {
        if (!this.state.isRegulationAccepted) {
            this.setState({
                noRegulationAccepted: true
            })
            return false;
        }
        return true;
    }

    private async subscribePayU(): Promise<void> {
        if (!this.validate()) {
            return;
        }


        let request;
        if (this.state.selectedPayMethod === 'card') {
            let cardToken: string | null | undefined = null
            try {
                cardToken = await this.tokenize();
                this.setState({
                    tokenizeError: null
                })
            }
            catch (error : any) {
                this.setState({
                    tokenizeError: error.message
                })
                return;
            }

            request = {
                productId: this.state.selectedProduct,
                payMethod: "CARD",
                cardToken: cardToken,
                promoCodeKey: this.state.addedPromoCode ? this.state.addedPromoCode.key : null,
                buyer: {
                    firstName: this.state.cardOwnerFirstName,
                    lastName: this.state.cardOwnerSecondName
                }
            }
        }
        else {
            request = {
                productId: this.state.selectedProduct,
                payMethod: "PBL",
                promoCodeKey: this.state.addedPromoCode ? this.state.addedPromoCode.key : null
            }
        }

        const apiController: APIController = new APIController(this.props.token);
        try {
            const response = await apiController.postOrder(request);

            if (this.state.selectedPayMethod === 'card') {
                switch (response.status) {
                    case "SUCCESS":
                        //płatność dokonana poprawnie, oczekujemy na notyfikację
                        this.props.history.push('/platnosc/' + response.orderId);
                        break;
                    case "WARNING_CONTINUE_3DS":
                        //płatność wymaga dodatkowego uwierzytelnienia płatności w procesie 3DSecure
                        window.location.href = response.redirectUri;
                        break;
                    case "WARNING_CONTINUE_CVV":
                        //potrzebne dodatkowe potwierdzenie CVV
                        break
                }
            }
            else {
                if (response.status == "SUCCESS") {
                   //płatność przyjęta do realizcji, przekierowujemy na stronę realizacji płatności
                    window.location.href = response.redirectUri;
                }
            }
        }
        catch (error: any) {
            if (error == "Istnieje niezakończone zamówienie dotyczące tego produktu") {
                this.setState({
                    orderAlreadyExistsError: true
                })
            }
            else
            super.setState({
                serverError: error,
                orderAlreadyExistsError: false
            })
        }
    }

    private async tokenize(): Promise<string | null | undefined> {
        if (this.payuSdkForms) {
            try {
                const result = await this.payuSdkForms.tokenize("MULTI");
                if (result.status === 'SUCCESS') {
                    return result.body.token
                }
                else {
                    const errorMessages: string = result.error.messages.map((m : any) => m.message).join("\r\n");
                    throw Error(errorMessages)
                }
            }
            catch (e : any) {
                throw Error(e.message);
            }
        }
        //return null;
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Purchase as any);
