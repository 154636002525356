import * as React from 'react';
import FlagIcon from './FlagIcon';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { Button } from 'reactstrap';
import APIController from '../helpers/APIController';
import SpeechController from '../helpers/SpeechController';
//import Iframe from 'react-iframe'
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import BaseComponent from './BaseComponent';
import SearchInput from './base/SearchInput';
import TranslatorPhrase from './TranslatorPhrase';
import NumberToUpperIndexConverter from './NumberToUpperIndexConverter';
import Sorter from '../helpers/Sorter';
import Flag from './Flag';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

type DictionaryState = {
    subscription: boolean | null
    dictionary: any | null
    phrasesList: any | null
    selectedPhrase: any
    selectedLanguage: string
    secondLanguage: string
}

class Dictionary extends BaseComponent<LoginProps, DictionaryState>{

    state: Readonly<DictionaryState> = {
        subscription: null,
        dictionary: null,
        phrasesList: null,
        selectedPhrase: {
            text: '',
            translation: '',
            definition: ''
        },
        selectedLanguage: 'pl',
        secondLanguage: ''
    }

    constructor(props: any) {
        super(props);
        this.searchInput = React.createRef<typeof SearchInput>();
    }

    public async componentDidMount() {
        super.load(() =>  this.loadDictionary());
    }

    public renderChild() {
        return (
            this.props.logged ?

                this.state.subscription !== null &&
                <React.Fragment>
                    {
                        this.state.subscription ?

                            this.renderDictionary()

                            :

                            <div>
                                <p>Brak uprawienień do słownika</p>
                            </div>
                    }
                </React.Fragment>

                :

                <Redirect to='/logowanie' />
        )
    }

    private renderDictionary(): React.ReactNode {

        return (
            this.state.dictionary &&

            <div className="dictionary-container">
                <h1>{this.state.dictionary.name}</h1>
                
                {this.state.dictionary.manual && this.renderManual()}
                <hr />
                <div className="translator_languages">
                    <span><Flag {...this.props} size={'2x'} code={this.state.selectedLanguage} /></span>
                    <span onClick={() => this.handleOnClickSwitchLanguages()}><i className="fa fa-refresh" aria-hidden="true"></i></span>
                    <span><Flag {...this.props} size={'2x'} code={this.state.secondLanguage} /></span>
                </div>
                <hr />
                <div className="dictionary-container_box">
                    {this.renderPhrases()}
                    <div>
                        <h3>Wpisz hasło poniżej</h3>
                        <SearchInput {...this.props} items={this.phrasesSearchList()} selectedItem={this.state.selectedPhrase.text} changeSelectedItem={this.setSelectedPhrase.bind(this)} ref={this.searchInput}/>
                        <hr />
                        <TranslatorPhrase {...this.props} phrase={this.state.selectedPhrase} selectedLanguage={this.state.selectedLanguage} secondLanguage={this.state.secondLanguage} />
                        <hr />
                    </div>
                </div>
            </div>
        )
    }

    private renderManual(): React.ReactNode {
        return (
            <div className="dictionary-manual">
                <hr />
                <div className="dictionary-manual-content" dangerouslySetInnerHTML={{ __html: this.state.dictionary.manual }}></div>
                <hr />
            </div>
        )
    }


    private renderPhrases(): React.ReactNode {
        return (
            <div className="phrases-list-container">
                <h3>Lista haseł</h3>
                <ul>
                    {
                        this.state.phrasesList.map((p: any, i: number) =>
                            <li key={i} onClick={() => this.setSelectedPhrase(p.text)}>
                                <NumberToUpperIndexConverter {...this.props} text={p.text } />
                            </li>
                        )
                    }
                </ul>
            </div>
            )
    }

    private async loadDictionary(): Promise<void> {
        try {
            const apiController: APIController = new APIController(this.props.token);
            const data = await apiController.getDictionary(parseInt(this.props.match.params.id));
            //data.phrases = data.phrases.sort((a: any, b: any) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : ((b.text.toLowerCase() > a.text.toLowerCase()) ? -1 : 0)); 

            const phrasesList = this.preparePhrases(data, data.language1);

            this.setState({
                subscription: true,
                dictionary: data,
                phrasesList: phrasesList,
                selectedPhrase: phrasesList[0],
                selectedLanguage: this.state.secondLanguage ? this.state.selectedLanguage : data.language1,
                secondLanguage: this.state.secondLanguage ? this.state.secondLanguage : data.language2
            })
        }
        catch (e) {
            this.setState({
                serverError: e
            })
        }
    }

    private preparePhrases(dictionary: any, selectedLanguage: string): Array<any> {

        const language = selectedLanguage == dictionary.language1 ? "lang1" : "lang2";

        let phrasesList = dictionary.phrases.map((p: any) => {
            let phrases: any;

            if (language == "lang1") {
                phrases = p.translations1.map((pp: any) => {

                    return {
                        text: pp,
                        synonyms: p.translations1.filter((t: any) => t != pp),
                        translations: p.translations2,
                        definition: p.definition,
                        expressions: p.expressions
                    }
                })
            }
            else {
                phrases = p.translations2.map((pp: any) => {

                    return {
                        text: pp,
                        synonyms: p.translations2.filter((t: any) => t != pp),
                        translations: p.translations1,
                        definition: p.definition,
                        expressions: p.expressions
                    }
                })
            }
            return phrases;
        }
        )
        phrasesList = phrasesList.flat();
        const phrasesListDistinct: any = []
        for (const item of phrasesList) {
            if (phrasesListDistinct.some((p: any) => p.text == item.text) == false)
                phrasesListDistinct.push(item);
        }

        const sorter: Sorter = new Sorter();
        phrasesList = sorter.sortAlphabetically(phrasesListDistinct, 'text', false);

        //phrasesList = phrasesListDistinct.sort((a: any, b: any) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : ((b.text.toLowerCase() > a.text.toLowerCase()) ? -1 : 0));
        //phrasesList = phrasesListDistinct.sort((a: any, b: any) => a.text.toLowerCase().localeCompare(b.text.toLowerCase(),'pl-PL') ? 1 : -1); 

        return phrasesList;
    }

    private phrasesSearchList(): Array<string> {
        return this.state.phrasesList.map((p: any) => p.text);
    }

    private setSelectedPhrase(phrase: string) {
        let selectedPhrase;
        if (phrase)
            selectedPhrase = this.state.phrasesList.find((p: any) => p.text === phrase)
        else
            selectedPhrase = this.state.phrasesList[0];

        this.setState({
            selectedPhrase: selectedPhrase
        })

        this.searchInput.current.changeFilteredText(selectedPhrase.text);
    }

    private async handleOnClickSwitchLanguages() {
        const selectedLanguage = this.state.secondLanguage 
        const secondLanguage = this.state.selectedLanguage

        const dictionary = this.state.dictionary;
        this.props.startLoad();
        const phrasesList = this.preparePhrases(dictionary, selectedLanguage);
        this.props.endLoad();

        this.setState({
            phrasesList: phrasesList,
            selectedLanguage: selectedLanguage,
            secondLanguage: secondLanguage
        })
        //this.searchInput.current.clear();
        this.searchInput.current.changeFilteredText(phrasesList[0].text);
    }
}

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Dictionary as any);
